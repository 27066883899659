// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-covid-js": () => import("./../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-help-js": () => import("./../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-question-js": () => import("./../src/pages/question.js" /* webpackChunkName: "component---src-pages-question-js" */),
  "component---src-pages-research-js": () => import("./../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-what-js": () => import("./../src/pages/what.js" /* webpackChunkName: "component---src-pages-what-js" */),
  "component---src-pages-where-js": () => import("./../src/pages/where.js" /* webpackChunkName: "component---src-pages-where-js" */),
  "component---src-pages-who-js": () => import("./../src/pages/who.js" /* webpackChunkName: "component---src-pages-who-js" */)
}

